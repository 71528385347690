import React from 'react'
import styled from 'styled-components'
import MainDemoRequestCTA from './MainDemoRequestCTA'
import Block from './Block'
import { SwarmiaBackgroundPattern } from './SwarmiaBackgroundPattern'

type Props = {
  children: React.ReactNode
  ctaComponent?: JSX.Element
}

export function CTASection({
  children,
  ctaComponent = <MainDemoRequestCTA />,
}: Props) {
  return (
    <CTASectionContainer className="layout-index">
      <SwarmiaBackgroundPattern />
      <Block>
        <h2>{children}</h2>
        {ctaComponent}
      </Block>
    </CTASectionContainer>
  )
}

export default CTASection

const CTASectionContainer = styled.div`
  text-align: center;
  padding: 64px 0 88px;
  position: relative;

  h2 {
    margin-bottom: 20px;
    max-width: 940px;
    color: white;
  }

  h2,
  .cta-form {
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (min-width: 737px) {
    padding: 148px 0 164px;
  }
`
